import { Component, h, Prop, Watch } from '@stencil/core';
import { Event, EventEmitter } from '@stencil/core';

/**
 * Creates a UI Brand Header element
**/
@Component({
  tag: 'csi-brand-header',
  styleUrls: ['./css/csi-brand-header.css'],
  assetsDirs: ['assets'],
  shadow: true,
})
export class CsiBrandHeader {
  /** The site name   */
  @Prop() sitename: string;

  /** The parent if it has one  */
  @Prop() siteparent: string;

  /** The link when clicking the site name  */
  @Prop() sitelink: string;

  /**
   * show the iowa logo as an overhanging tabl on large screens.  Default is false
   */
  @Prop() logotab?: boolean = false;

  /**
   * Message to show the user "Welcome, Jane" "Welcome, jlane" or similar
   */
  @Prop() welcomeText?: string = '';

  /**
   * Show a logout button, defaults to false
   */
  @Prop() showLogout?: boolean = false;

  /**
   * Text on logout button
   */
  @Prop() logoutText?:string = 'Logout';
  @Watch('logoutText')
  watchLogoutText() {
    this.validateLogoutText();
  }
  @Event({
    eventName: 'logoutClicked',
    bubbles: true,
    cancelable: true,
    composed: true
  })
  logoutClicked: EventEmitter<boolean>;
  public handleLogout = () => {
    this.logoutClicked.emit(true);
  }
  constructor() {
    this.validateLogoutText();
  }

  private validateLogoutText(): void {
    if ( (this.showLogout ?? false) === true && (this.logoutText ?? '') === '') {
      this.logoutText = 'Logout';
    }
  }
  render() {
    return (
      <header class="iowa-bar iowa-bar--narrow">
        <span class="visually-hidden">
          <a href="https://www.uiowa.edu" class="cdk-visually-hidden">
            <div>The University of Iowa</div>
          </a>
        </span>
        <div class="brand-flex-row brand-flex-start" part="header-div">
          <div class={this.logotab ? 'logo logo--tab with-tab' : 'logo logo--tab'} part="logo-div">
            <div class="flex-row center" part="svg-div">
              <a href="https://www.uiowa.edu" part="svg-anchor" class="svg-anchor">
                <svg
                  part="svg-elem"
                  xmlns="http://www.w3.org/2000/svg"
                  class="logo-icon"
                  aria-labelledby="logo-header"
                  role="img"
                  viewBox="0 0 311.6 90.2"
                >
                  <path class="st0" d="M40 18.8h-7.3v52.4H40v19H0v-19h7.3V18.8H0V0h40V18.8z" />
                  <path
                    class="st0"
                    d="M93.8 90.2h-29c-10.5 0-17.4-6.9-17.4-18.2V18.2C47.4 7 54.4 0 64.8 0h29c10.5 0 17.4 7 17.4 18.2V72C111.2 83.2 104.2 90.2 93.8 90.2zM85.6 71.2V18.8H73v52.4H85.6z"
                  />
                  <path
                    class="st0"
                    d="M122.6 18.8h-6.4V0h38v18.9H147l6.5 43.4L167 0h19.2l14.4 62.3 5.2-43.4h-6.6V0h37.5v18.9h-6.2l-11.3 71.4h-30.6l-11.8-53.2 -12.1 53.1h-29.4L122.6 18.8z"
                  />
                  <path
                    class="st0"
                    d="M230.1 71.2h6.9L250.7 0h41l13.5 71.2h6.4v19H281l-2.9-22h-15.2l-2.7 22h-30L230.1 71.2 230.1 71.2zM276.5 51.7l-5.8-36.4 -6 36.4H276.5z"
                  />
                </svg>
              </a>
            </div>
          </div>
          <span class="site-messages-wrapper">
            <h1 class="site-name" part="site-name">
              <a href={this.sitelink} part="site-anchor">{this.sitename}</a>
            </h1>
            {
              ( (this.showLogout ?? false) !== false || (this.welcomeText ?? '') !== '') &&
              <span class="welcome-logout" part="welcome-logout">
                {
                  ( (this.welcomeText ?? '') !== '') &&
                  <span class="welcome-message" part="welcome-message">
                    {this.welcomeText}
                  </span>
                }
                {
                  ( (this.showLogout ?? false) !== false) &&
                  <span class="logout-button-wrapper" part="logout-button-wrapper">
                    <button
                      class="logout-button"
                      part="logout-button"
                      onClick={ this.handleLogout }
                      onKeyUp={ this.handleLogout }
                    >
                      <span class="logout-text" part="logout-text">
                        <pre>{this.logoutText}</pre>
                      </span>
                    </button>
                  </span>
                }
              </span>
            }
          </span>
        </div>
      </header>
    );
  }
}
