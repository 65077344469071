@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url("https://use.fontawesome.com/releases/v5.8.2/css/all.css");

:host * {
  top: 0;
  left: 0;
  font-size: 16px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  line-height: 1.15;
  touch-action: manipulation;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  --ui-brand-gold: #ffcd00;
  --ui-brand-black: #151515;
  --border-radius: 4px;
  --default-border: 1px solid black;
}

:host header {
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
}
:host *::before, *::after {
  box-sizing: inherit;
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0,0,0,0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

a:nth-child(odd) {
  background: var(--ui-brand-black);
}

.brand-flex-row {
  display: flex;
  flex-direction: row;
}
.brand-flex-start {
  justify-content: flex-start;
}

.site-name {
  display: flex;
  align-items: center;
  margin: 0 auto 0 0;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1;
  font-family: "Roboto", sans-serif;
}
.site-name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.logo-icon {
  fill: var(--ui-brand-gold);
}
.logo-icon:hover,
.logo-icon:focus {
  opacity: 0.7;
}
.logo--tab {
  padding: 1.688rem 1.25rem 1.25rem;
  margin-left: 2.5%;
}
.logo .logo--tab {
  padding: 1.625rem;
  min-height: 5rem;
  height: 5rem;
  max-height: 5rem;
  display: inline-block;
}

/* iowa-bar
-------------------------------------- */
.iowa-bar {
  position: relative;
  z-index: 2;
  display: block;
  min-height: auto;
  background: var(--ui-brand-gold);
}
.iowa-bar .site-name {
  width: auto;
  background: transparent;
  padding: 0;
}
.iowa-bar .logo--tab {
  background-color: var(--ui-brand-black);
}

/* iowa-bar--narrow
-------------------------------------- */
.iowa-bar--narrow .logo--tab {
  margin-right: 0.625rem;
}

.iowa-bar--narrow .logo-icon {
  height: 1.25rem;
}
.iowa-bar--narrow .site-name,
.iowa-bar--narrow .site-name a,
.iowa-bar--narrow .site-name a:visited,
.iowa-bar--narrow .site-name a:hover,
.iowa-bar--narrow .site-name a:active {
  font-size: 1rem;
  margin: 0;
  background: var(--ui-brand-gold);
  color: var(--ui-brand-black);
  text-decoration: none;
  padding: 0;
}
.iowa-bar--narrow .logo-icon {
  height: 1.625rem;
}
.iowa-bar--narrow {
  min-height: 3.125rem;
  position: relative;
}

/* header (this section needs to be reviewed and tested)
-------------------------------------- */
header {
  -webkit-font-smoothing: antialiased;
}
@media (min-width: 1350px) {
  .iowa-bar--narrow .logo--tab {
    margin-bottom: -0.375rem;
    height: 3.5rem;
    width: 7.438rem;
    padding: 0.938rem;
  }
}

@media (max-width: 854.999px) {
  header {
    margin-bottom: 0;
  }
  .logo--tab {
    margin-left: 0 !important;
  }
  .logo--tab {
    height: 4.375rem;
    width: 6.813rem;
    padding: 1.625rem 1.25rem;
    background: var(--ui-brand-black);
  }
  .iowa-bar--narrow .logo-icon {
    height: 1.25rem;
  }
}

span.site-messages-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.welcome-logout {
  margin-right: 20px;
  margin-left: 20px;
  height: 100%;
  display: flex;
  align-items: center;
}

.logout-button-wrapper {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.logout-button {
  min-height: 2.5rem;
  height: 2.5rem;
  background-color: var(--ui-brand-gold);
  color: black;
  border: var(--default-border);
  border-radius: var(--border-radius);
  padding-left:10px;
  padding-right: 10px;
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logout-button:hover {
  color: black;
  background-color: var(--ui-brand-gold);
  border: var(--default-border);
}

.logout-button:active {
  color: var(--ui-brand-gold);
  background-color: black;
}

.site-name a:focus,
.logout-button:focus {
  outline-offset: 5px;
  outline: 4px solid black;
}
a.svg-anchor:focus {
  outline-offset: 5px;
  outline: 4px solid white;
}
